
export default {
  ui: {
    submit: '[data-submit]',
  },

  events: {
    'submit': 'disableButton',
  },

  initialize(options) {
    this.form = options.el
  },

  disableButton(event) {
    this.ui.submit[0].disabled = true
  }
}
