const AddonForm = {
  ui: {
    select: 'select',
  },

  events: {
    'change {select}': 'submitForm',
  },

  initialize(options) {
    this.form = options.el
  },

  submitForm(event) {
    this.form.submit()
  }
}

export default AddonForm
