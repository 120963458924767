/**
 * Focus
 * A simple stack to keep track of focus. Active elements are "pushed" and "popped"
 * from the stack. This is a manual process. See components like modal-content.js
 * for usage.
 */

export const focusStack = []

export function popFocus() {
  let el = focusStack.pop()

  if (el && typeof el.focus === 'function') {
    el.focus()
  }
}

export function pushFocus() {
  if (document.activeElement) {
    focusStack.push(document.activeElement)
  }
}

export function clearFocusStack () {
  focusStack.length = 0
}
