import Washi          from 'washi'

import Order          from '../models/order'
import ProductFields  from './product-fields'
import ApplyDiscount  from '../../ecomm/views/apply-discount'
import DonationPromo  from './donation-promo'
import PromotionModal from './promotion-modal'
import CartSummary    from './cart-summary'

const forcePaymentDebugMode = false

export default {

  ui : {
    form         : 'form',
    sentryTags    : '[data-sentry-tags]'
  },

  initialize(options) {
    this.order = new Order()

    this.productFields = Washi(ProductFields, {
      el    : '[data-component=product-fields]',
      order : this.order
    }).get(0)

    this.applyDiscount = Washi(ApplyDiscount, {}).get(0)

    this.donationPromo = Washi(DonationPromo, {
      el    : '[data-component=donation-promo]',
      order : this.order
    }).get(0)

    this.promotionModal = Washi(PromotionModal, {
      el    : '[data-component=promotion-modal]',
      order : this.order
    }).get(0)

    this.cartSummary = Washi(CartSummary, {
      el    : '[data-component=cart-summary]',
      order : this.order
    }).get(0)

    // Listen for donation removals
    if (this.cartSummary) {
      this.cartSummary.listen(this.handleRemoveDonation, this)
    }

    this.setSentryTagsContent()
  },

  handleRemoveDonation() {
    this.donationPromo.removeDonation()
  },

  handlePayment(payment) {
    if (payment.isApproved()) {
      console.log('Payment approved!')
    }

    console.log(payment)
    console.log(JSON.stringify(payment))

    this.ui.form[0].submit()
  },

  setSentryTagsContent() {
    if (window.Sentry !== undefined) {
      const sentryTagsHash = this.sentryTags()
      Object.keys(sentryTagsHash.tags).forEach(function (key) {
        const value = sentryTagsHash.tags[key]
        window.Sentry.setTag(key, value)
      })
      Object.keys(sentryTagsHash.extra).forEach(function (key) {
        const value = sentryTagsHash.extra[key]
        window.Sentry.setExtra(key, value)
      })
    }
  },

  sentryTags() {
    try {
      return JSON.parse(this.ui.sentryTags[0].getAttribute('data-sentry-tags'))
    } catch (e) {
      return {
        tags  : {},
        extra : {}
      }
    }
  }
}
