import History from '../../utils/history-map'
import {pushFocus, popFocus} from '../../utils/focus-stack'

export default {

  ui: {
    content: '[data-modal-content]'
  },

  initialize() {
    this.addBackdrop()
    this.checkHistory()

    window.addEventListener('focus', this.trapFocus.bind(this), true)
    window.addEventListener('popstate', this.checkHistory.bind(this), true)
  },

  outOfBounds(el) {
    return el == window || this.el.contains(el) == false
  },

  isVisible() {
    return this.el.hasAttribute('aria-hidden') == false
  },

  trapFocus(event) {
    if (this.isVisible() && this.outOfBounds(event.target)) {
      this.focus()
    }
  },

  focus() {
    this.el.focus()
  },

  checkHistory() {
    if (History.get('modal') == this.el.id) {
      this.open()
    } else {
      this.close()
    }
  },

  pushState() {
    History.set('modal', this.el.id)
  },

  popState() {
    History.delete('modal')
  },

  addBackdrop() {
    let backdrop = document.createElement('div')

    backdrop.className = 'modal__backdrop'
    backdrop.setAttribute('data-close', true)

    this.el.insertBefore(backdrop, this.el.firstChild)
  },

  close() {
    this.$el.removeClass('-active -in')
    this.el.setAttribute('aria-hidden', 'true')
    this.el.tabIndex = -1

    document.body.classList.remove('overflow-no-scroll')

    popFocus()

    this.popState()

    this.ui.content.forEach(el => el.scrollTop = 0)
  },

  open() {
    pushFocus()

    this.$el.addClass('-active -in')
    this.el.removeAttribute('aria-hidden')
    this.el.tabIndex = 0

    this.focus()

    this.pushState()

    document.body.classList.add('overflow-no-scroll')
  }
}
