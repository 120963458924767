import Washi from 'washi'
import Diode from 'diode'
import { BigNum, sum, roundToCents } from '../../utils/currency'
import { stringIsPresent } from '../../utils/is-present'

const $ = Washi.$


class Order {

  constructor(items=[]) {
    // Add basic event subscription
    Diode(this)

    this.firstName   = ''
    this.lastName    = ''
    this.email       = ''
    this.phoneNumber = ''
    this.lineItems   = items
    this.donation    = BigNum('0')
    this.payment     = null
  }

  update(attributes) {
    Object.assign(this, attributes)

    this.publish(this, Object.keys(attributes))
  }

  get totalBasePrice() {
    return sum(this.lineItems.map(i => i.totalBasePrice))
  }

  get totalTicketPrice() {
    return sum(this.tickets.map(i => i.totalPrice))
  }

  get totalTax() {
    return sum(this.lineItems.map(i => i.totalTax))
  }

  get totalPrice() {
    return sum(this.lineItems.map(i => i.totalPrice).concat(this.donation))
  }

  get numberOfTickets() {
    return sum(this.tickets.map(i => i.quantity))
  }

  get tickets() {
    return this.lineItems.filter(i => i.isTicket())
  }

  hasDonation() {
    return this.donation.gt(0)
  }

  ticketTypeQuantities() {
    return this.tickets.reduce(function(memo, ticket) {
      let type = ticket.ticketType

      memo[type] = (memo[type] || 0) + parseInt(ticket.quantity.toString(), 10)

      return memo
    }, {})
  }

  isValid() {
    return this.hasAtLeastOneTicket() &&
            this.totalGreaterThanZero() &&
            this.firstNameIsPresent() &&
            this.lastNameIsPresent() &&
            this.emailIsPresent() &&
            this.phoneNumberIsPresent()
  }

  hasAtLeastOneTicket() {
    return this.numberOfTickets.gt(BigNum(0))
  }

  totalGreaterThanZero() {
    return this.totalPrice.gt(BigNum('0'))
  }

  firstNameIsPresent() {
    return stringIsPresent(this.firstName)
  }

  lastNameIsPresent() {
    return stringIsPresent(this.lastName)
  }

  emailIsPresent() {
    return stringIsPresent(this.email)
  }

  phoneNumberIsPresent() {
    return stringIsPresent(this.phoneNumber)
  }
}

export default Order
