import { inRange } from './helpers'

export default function family (model) {
  let items = model.ticketTypeQuantities()

  let noSeniors     = inRange(items.senior, 0, 0)
  let correctAdults = inRange(items.adult, 2, 3)
  let hasChildren   = inRange(items.child, 1, 4)

  return noSeniors && correctAdults && hasChildren
}
