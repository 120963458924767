import Diode from 'diode'

export default {

  ui: {
    codeField : '[data-discount-code-field]'
  },

  events: {
    'input {codeField}' : 'codeChanged'
  },

  initialize(options) {
    Diode(this)
  },

  codeChanged() {
    this.publish(this.code())
  },

  code() {
    return this.ui.codeField[0].value
  }

}
