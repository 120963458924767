import { priceWithCommas } from '../../utils/currency'

class LineItemPresenter {
  constructor(lineItem) {
    this.lineItem = lineItem
  }

  get basePrice() {
    return this.lineItem.basePrice
  }

  get label() {
    return this.lineItem.label
  }

  get quantity() {
    return this.lineItem.quantity
  }

  get totalBasePrice() {
    return priceWithCommas(this.lineItem.totalBasePrice)
  }
}

export default LineItemPresenter
