import { priceWithCommas } from '../../utils/currency'

import LineItemPresenter from './line-item-presenter'

class OrderPresenter {
  constructor(order) {
    this.order = order
  }

  get lineItems() {
    return this.order.lineItems.map(i => new LineItemPresenter(i))
  }

  get tickets() {
    return this.order.tickets.map(i => new LineItemPresenter(i))
  }

  get totalBasePrice() {
    return priceWithCommas(this.order.totalBasePrice)
  }

  get totalTax() {
    return priceWithCommas(this.order.totalTax)
  }

  get totalPrice() {
    return priceWithCommas(this.order.totalPrice)
  }

  get totalTicketPrice() {
    return priceWithCommas(this.order.totalTicketPrice)
  }

  get hasDonation() {
    return this.order.hasDonation()
  }

  get donationAmount() {
    return priceWithCommas(this.order.donation)
  }
}

export default OrderPresenter
