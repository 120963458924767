const History = window.history || {
  state: {},

  pushState(state) {
    History.state = state
  }
}

export default {
  get(key) {
    return History.state ? History.state[key] : undefined
  },

  delete(key) {
    let next = Object.assign({}, History.state)

    if (key in next) {
      delete next[key]
      History.pushState(next, null, null)
    }
  },

  set (key, value) {
    if (this.get(key) !== value) {
      History.pushState({ [key]: value }, null, null)
    }
  }
}
