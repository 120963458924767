export default {
  ui: {
    message: '[data-countdown-message]',
    timer: '[data-countdown-timer]',
    expirationMessage: '[data-countdown-expiration-message]'
  },

  initialize() {
    // calculate the target time (when the countdown should stop)
    var secondsRemaining = parseInt(this.el.getAttribute('data-countdown-seconds-remaining'))
    var nowOffset = new Date().getTime()
    var target = new Date(nowOffset + secondsRemaining * 1000)

    // start the timer
    this.tick = this.tick.bind(this)
    this.tick(target)
  },

  tick(target) {
    // calculate seconds remaining
    var remainingSeconds = parseInt((target.getTime() - new Date().getTime()) / 1000)

    // update the displayed timer
    var timerValue = this.formatTimer(remainingSeconds)
    this.ui.timer[0].innerText = timerValue

    // stop the timer if it has expired
    if (remainingSeconds > 0) {
      setTimeout(this.tick, 1000, target)
    } else {
      this.expire()
      this.disable()
    }
  },

  // formats an integer into a minutes and seconds duration, e.g. '3:05'
  formatTimer(remainingSeconds) {
    var min = Math.floor(remainingSeconds / 60).toFixed()
    var sec = Math.floor(remainingSeconds % 60)

    // padStart isn't supported by Internet Explorer. When we stop supporting IE,
    // remove this and add .toFixed().padStart(2, "0") to the sec line above.
    if(sec <= 9) {
      sec = "0" + sec.toFixed()
    } else {
      sec = sec.toFixed()
    }

    return min + ":" + sec
  },

  // hide the timer and show the expiration message
  expire() {
    this.ui.message[0].classList.add("hide")
    this.ui.expirationMessage[0].classList.remove("hide")
  },

  // if a disable selector is set, then add the disabled attribute
  // #5632 - created to prevent a user from continuing the checkout phase after
  // the coundown completes
  disable() {
    // check if a disable selector has been set, and grab the element
    var disableSelector = this.el.getAttribute('data-disable') || false;
    var disableEl = disableSelector ? document.querySelector(disableSelector) : null;

    // if there is an element, disable it
    if (disableEl) {
      disableEl.setAttribute('disabled', true)
    }
  }
}
