import Washi from 'washi'

import DiscountCode from './discount-code'
import SeniorDiscount from './senior-discount'

import { stringIsPresent } from '../../utils/is-present'

export default {
  ui : {
    submitButton : '[data-submit-button]',
    seniorToggle : '[data-component="senior-discount"]'
  },

  strings : {
    continue      : 'Continue',
    applyDiscount : 'Apply Discount &amp; Continue'
  },

  initialize(options) {
    this.discountCode = Washi(DiscountCode, {
      el : '[data-component=discount-code]'
    }).get(0)

    this.seniorDiscount = Washi(SeniorDiscount, {}).get(0)

    // Listen for discount input changes
    if (this.discountCode) {
      this.discountCode.listen(this.handleDiscountCode, this)

      // Set initial state of submit button
      this.handleDiscountCode(this.discountCode.code())
    }

    if (this.seniorDiscount) {
      this.seniorDiscount.listen(this.handleDiscountCode, this)
    }
  },

  seniorToggleChecked() {
    let seniorToggle = this.ui.seniorToggle[0]

    if(seniorToggle) {
      return seniorToggle.checked
    }

    return false
  },

  handleDiscountCode(code) {
    if (stringIsPresent(code) && !this.seniorToggleChecked()) {
      this.ui.submitButton.forEach((el) => el.innerHTML = this.strings.applyDiscount)
    } else {
      this.ui.submitButton.forEach((el) => el.innerHTML = this.strings.continue)
    }
  }
}
