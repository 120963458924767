import { BigNum, roundToCents } from '../../utils/currency'

class LineItem {

  constructor({
    quantity,
    label,
    productType,
    ticketType,
    taxType,
    unitPrice,
    unitTax,
    basePrice,
  }) {
    this.quantity     = BigNum(quantity)
    this.label        = label
    this.productType  = productType
    this.ticketType   = ticketType
    this.taxType      = taxType
    this.unitPrice    = BigNum(unitPrice)
    this.unitTax      = BigNum(unitTax)
    this.basePrice    = BigNum(basePrice)
  }

  get totalPrice() {
    return this.totalBasePrice.plus(this.totalTax)
  }

  get totalBasePrice() {
    return this.basePrice.times(this.quantity)
  }

  get totalTax() {
    return this.unitTax.times(this.quantity)
  }

  isTicket() {
    return this.productType == 'ticket'
  }

  isAddOn() {
    return this.productType == 'addon'
  }

  notTaxed() {
    return this.taxType == 'none'
  }

  taxSeparate() {
    return this.taxType == 'separate'
  }

  taxIncluded() {
    return this.taxType == 'included'
  }

}

export default LineItem
