import Washi from 'washi'
import { BigNum, setPrice, priceWithCommas } from '../../utils/currency'

import Order        from '../models/order'
import ModalContent from './modal-content'
import strategies   from '../strategies'

export default {

  ui: {
    close             : '[data-close]',
    remove            : '[data-remove]',
    modal             : '[data-modal]',
    open              : '[data-open]',
    priceComparison   : '[data-price-comparison]',
    singleDayPrice    : '[data-single-day-price]',
    singleDayQuantity : '[data-single-day-quantity]'
  },

  events: {
    'click {close}'  : 'closeModal',
    'click {open}'   : 'openModal',
    'click {remove}' : 'remove'
  },

  initialize(options) {
    this.modal     = Washi(ModalContent, { el: this.ui.modal }).get(0)
    this.strategy  = this.el.getAttribute('data-strategy')
    this.dismissed = false

    this.order.listen(this.orderChanged, this)

    window.addEventListener('keyup', this.onKeyInput.bind(this), true)

    this.render()
  },

  onKeyInput(event) {
    // Close modal on ESC
    if (event.keyCode === 27) {
      this.closeModal()
    }
  },

  orderChanged(_, changes) {
    if (changes.indexOf('lineItems') != -1) {
      this.render()
    }
  },

  render() {
    if (this.dismissed) {
      return
    }

    const strategy = strategies[this.strategy]

    if (strategy) {
      if (strategy(this.order)) {
        this.show()
      } else {
        this.hide()
      }
    }

    this.updatePriceComparison()
    this.updateSingleDayPrice()
    this.updateSingleDayQuantity()
  },

  shouldInterruptFlow() {
    return this.el.hasAttribute('data-interrupt')
  },

  show() {
    this.el.style.display = 'block'

    // Immediately show promotions as soon as they are enabled
    if (this.shouldInterruptFlow()) {
      this.openModal()
    }
  },

  hide() {
    this.el.style.display = 'none'
  },

  openModal() {
    this.modal.open()
  },

  closeModal() {
    this.modal.close()
  },

  remove() {
    this.dismissed = true

    this.hide()
    this.closeModal()
  },

  strategyPrice() {
    return BigNum(this.el.getAttribute('data-price'))
  },

  // Price comparison works like this:
  //
  // 1. Sutract the current total from the baseline to get the
  //    price difference
  // 2. Display an upsell message using the difference
  updatePriceComparison() {
    let total = this.order.totalTicketPrice

    this.ui.priceComparison.forEach(function(el) {
      let baseline = this.strategyPrice()
      let cost     = baseline.minus(total)
      let zero     = BigNum('0')
      let message  = ''

      if (cost.gt(zero)) {
        message = `For only $${ priceWithCommas(cost) } more,`

      } else if (cost.lt(zero)) {
        message = `Save $${ priceWithCommas(cost.abs()) } and`

      } else {
        message = `For the same price,`
      }

      el.innerHTML = message
    }, this)
  },

  // Single day price is the current total plus parking
  updateSingleDayPrice() {
    let total = this.order.totalTicketPrice

    this.ui.singleDayPrice.forEach(function(el) {
      setPrice(el, total)
    }, this)
  },

  updateSingleDayQuantity() {
    this.ui.singleDayQuantity.forEach(function(el) {
      el.innerHTML = this.order.numberOfTickets
    }, this)
  }
}
