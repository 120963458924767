import Diode from 'diode'

export default {

  ui: {
    toggle : '[data-component="senior-discount"]',
    codeField : '[data-discount-code-field]',
    codeSection : '[data-component="discount-code"]'
  },

  events: {
    'change {toggle}' : 'toggleDiscount'
  },

  initialize(options) {
    Diode(this)

    // set initial state on page load
    this.toggleDiscount()
  },

  toggleDiscount() {
    let toggle = this.ui.toggle[0]

    if (toggle) {
      if (toggle.checked) {
        this.updateCode(toggle.value, true)
      } else if (this.ui.codeField[0].value === toggle.value) {
        this.updateCode("", false)
      }
    }
  },

  updateCode(code, hideCode) {
    this.ui.codeField[0].value = code
    this.publish(code)

    this.ui.codeSection[0].classList.toggle('hide', hideCode)
  }

}
