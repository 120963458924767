import Diode from 'diode'
import Washi from 'washi'
import Mustache from 'mustache'

import Order from '../models/order'
import OrderPresenter from '../presenters/order-presenter'

export default {

  ui: {
    templates: '[data-template]'
  },

  initialize(options) {
    Diode(this)

    if (this.ui.templates.length) {
      this.template = this.ui.templates[0].textContent
    } else {
      this.template = ''
    }

    this.order.listen(this.orderChanged, this)

    // Listen for 'Remove' clicks
    this.el.addEventListener('click', this.handleClick.bind(this))

    this.render()
  },

  orderChanged(_, changes) {
    if (changes.indexOf('lineItems') != -1 || changes.indexOf('donation') != -1) {
      this.render()
    }
  },

  render() {
    this.el.innerHTML = Mustache.render(this.template, new OrderPresenter(this.order))
  },

  handleClick(e) {
    if (Washi.$.matches(e.target, '[data-remove-donation]')) {
      e.preventDefault()

      this.publish()
    }
  }

}
