export function hide (el) {
  if (Array.isArray(el)) {
    return el.map(hide)
  }
  el.style.display = 'none'
  el.setAttribute('aria-hidden', true)
}

export function show (el) {
  if (Array.isArray(el)) {
    return el.map(show)
  }
  el.classList.remove('hide')
  el.style.display = '';
  el.removeAttribute('aria-hidden')
}

export function isHidden (el) {
  return el.hasAttribute('aria-hidden')
      || el.style.display === 'none'
      || el.classList.contains('hide')
}

export function isVisible (el) {
  return !isHidden(el)
}
