import LineItem from '../models/line-item'

export default {

  events: {
    'change [data-quantity]' : 'scrapeTable'
  },

  initialize(options) {
    this.order = options.order

    this.scrapeTable()
  },

  scrapeTable() {
    let products = Array.prototype.slice.call(this.el.querySelectorAll('[data-product]'))

    let items = products.map(el => {
      let field = el.querySelector('[data-quantity]')

      return new LineItem({
        quantity:    field.value,
        label:       field.getAttribute('data-label'),
        productType: field.getAttribute('data-product-type'),
        ticketType:  field.getAttribute('data-ticket-type'),
        taxType:     field.getAttribute('data-tax-type'),
        unitPrice:   field.getAttribute('data-unit-price'),
        basePrice:   field.getAttribute('data-base-price'),
        unitTax:     field.getAttribute('data-unit-tax')
      })
    })

    this.order.update({ lineItems: items.filter(i => i.quantity > 0) })
  }

}
