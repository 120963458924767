import { inRange } from './helpers'

export default function dual (model) {
  let quantities = model.ticketTypeQuantities()

  let noSeniors     = inRange(quantities.senior, 0, 0)
  let noChildren    = inRange(quantities.child, 0, 0)
  let correctAdults = inRange(quantities.adult, 2, 2)

  return noSeniors && noChildren && correctAdults
}
