/**
 * Calendar Modal
 *
 * A date picker, as seen on timed ticket selection pages.
 *
 * Follows some of the pattern at
 * https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/datepicker-dialog.html
 *
 */

const Washi = require('washi')
const Modal = require('../../components/mixins/modal')
const URLSearchParams = require('@ungap/url-search-params/cjs')

const CELL_CLASSES = "calendar-modal__cell cell text-center type-body-medium-large"
const DAY_CLASSES = `${CELL_CLASSES} cursor-pointer pad-1-vertical`
const DAY_DATE_ATTR = 'data-calendar-modal-date'
const DAY_NAMES = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
const DAY_SEARCH_PARAM = "start_at"
const DAY_SEARCH_PARAM_VALUE_SUFFIX = "+00:00:00+-0500"
const KEYS = {
  down: 40,
  enter: 13,
  esc: 27,
  left: 37,
  right: 39,
  up: 38,
}
const MONTH_NAMES = [
  "January", "February", "March",
  "April", "May", "June",
  "July", "August", "September",
  "October", "November", "December"
]
const OTHER_MONTH_DAY_HTML = `<div class="${DAY_CLASSES} fill-gorilla"}></div>`
const PAGE_DATE_ATTR = 'data-calendar-modal-page-date'
const SELECTED_CLASS = '-selected'
const URL = `${window.location.origin}${window.location.pathname}`
const WRAPPER_CLASSES = "calendar-modal__grid grid grid-seventh"

const CalendarModal = Washi.$.extend({
  ui: {
    backdrop: '[data-calendar-modal="backdrop"]',
    contents: '[data-calendar-modal="contents"]',
    days: '[data-calendar-modal="days"]',
    head: '[data-calendar-modal="head"]',
    hideButton: '[data-calendar-modal="hide"]',
    modal: '[data-calendar-modal="modal"]',
    month: '[data-calendar-modal="month"]',
    nextMonthButton: '[data-calendar-modal="next"]',
    prevMonthButton: '[data-calendar-modal="prev"]',
    showButton: '[data-calendar-modal="show"]',
    submitButton: '[data-calendar-modal="submit"]',
  },

  events: {
    'click {backdrop}': 'handleBackdropClick',
    'click {hideButton}': 'handleHideButtonClick',
    'click {nextMonthButton}': 'handleNextMonthButtonClick',
    'click {prevMonthButton}': 'handlePrevMonthButtonClick',
    'click {showButton}': 'handleShowButtonClick',
    'click {submitButton}': 'handleSubmitButtonClick',
  },

  initialize(options) {
    this.el = this.ui.modal[0] // used by Modal.hide
    this.hideFocusEl = this.ui.showButton[0] // used by Modal.handleWindowKeydown

    const date = new Date(options.el.getAttribute(PAGE_DATE_ATTR))
    const today = new Date()

    // today is used to disable selecting past dates
    this.todayDate = today.getDate()
    this.todayMonth = today.getMonth()
    this.todayYear = today.getFullYear()

    // "page date" is the first day in the page's listing
    // it is used to initialize the calendar every time it's shown
    this.pageDate = date.getDate()
    this.pageMonth = date.getMonth()
    this.pageYear = date.getFullYear()

    this.state = {
      // what's visible
      displayedDayEls: null,
      displayedMonth: null,
      displayedMonthLastDate: null,
      displayedYear: null,

      // what's selected (may or may not be in the visible month)
      selectedDate: null,
      selectedMonth: null,
      selectedYear: null,

      // what's selected in the visible month
      selectedDisplayedDayEl: null,
    }

    this.ui.submitButton[0].href = this.getSubmitButtonHref()
  },

  Days({ displayingTodayMonth, displayingSelectedMonth, displayedMonthLastDate, displayedMonthAndYear }) {
    const prevMonthVisibleDayCount = new Date(this.state.displayedYear, this.state.displayedMonth, 1).getDay()
    const nextMonthVisibleDayCount = 6 - displayedMonthLastDate.getDay()

    const day = ({date, past, selected}) => {
      const dayHtml = `
        <button class="${DAY_CLASSES} ${selected ? SELECTED_CLASS : ''}"
              ${DAY_DATE_ATTR}="${date}"
              ${selected ? '' : 'tabindex="-1"'}
              ${past ? 'disabled' : ''}>
          ${date + 1} <span class="hide-visually">${displayedMonthAndYear}</span>
        </button>`

      return dayHtml
    }

    let prevMonthDaysHtml = ""
    let curMonthDaysHtml = ""
    let nextMonthDaysHtml = ""

    for (let i = 0; i < prevMonthVisibleDayCount; i++) {
      prevMonthDaysHtml += OTHER_MONTH_DAY_HTML
    }

    for (let j = 0; j < this.state.displayedMonthLastDate; j++) {
      const past = displayingTodayMonth && j < this.todayDate - 1
      const selected = displayingSelectedMonth && j === this.state.selectedDate

      curMonthDaysHtml += day({date: j, past: past, selected: selected})
    }

    for (let k = 0; k < nextMonthVisibleDayCount; k++) {
      nextMonthDaysHtml += OTHER_MONTH_DAY_HTML
    }

    const html = `<div class="${WRAPPER_CLASSES}">
        ${prevMonthDaysHtml}
        ${curMonthDaysHtml}
        ${nextMonthDaysHtml}
      </div>
    `

    return html
  },

  getSubmitButtonHref() {
    const searchParams = new URLSearchParams(window.location.search)
    const daySearchParamValuePrefix = `${this.state.displayedYear}-${(this.state.displayedMonth + 1)}-`

    searchParams.set(DAY_SEARCH_PARAM, `${daySearchParamValuePrefix}${this.state.selectedDate + 1}${DAY_SEARCH_PARAM_VALUE_SUFFIX}`)

    return `${URL}?${searchParams.toString()}`
  },

  handleBackdropClick(e) {
    let contentsClicked = e.target == this.ui.contents[0] || this.ui.contents[0].contains(e.target)

    if (!contentsClicked) {
      this.hideModal(e)
    }
  },

  handleDayClick(e) {
    if (this.state.selectedDisplayedDayEl) {
      this.state.selectedDisplayedDayEl.classList.remove(SELECTED_CLASS)
      this.state.selectedDisplayedDayEl.setAttribute('tabindex', -1)
      this.state.selectedDisplayedDayEl.removeAttribute('aria-selected')
    } else {
      this.ui.submitButton[0].removeAttribute('disabled')
    }

    // update state
    this.state.selectedDisplayedDayEl = e.target
    this.state.selectedYear = this.state.displayedYear
    this.state.selectedMonth = this.state.displayedMonth
    this.state.selectedDate = parseInt(this.state.selectedDisplayedDayEl.getAttribute(DAY_DATE_ATTR))

    // update DOM
    this.state.selectedDisplayedDayEl.classList.add(SELECTED_CLASS)
    this.state.selectedDisplayedDayEl.removeAttribute('tabindex')
    this.state.selectedDisplayedDayEl.setAttribute('aria-selected', true)
    this.ui.submitButton[0].href = this.getSubmitButtonHref()
  },

  handleDayKeydown(e) {
    const shiftSelection = (count) => {
      const index = this.state.selectedDate + count

      if (index < 0) { // incoming day is in previous month
        this.ui.prevMonthButton[0].click()
        this.state.displayedDayEls[this.state.displayedMonthLastDate + index].click()
        return
      } else if (index >= this.state.displayedMonthLastDate) { // incoming day is in next month
        const remainder = index - this.state.displayedMonthLastDate

        this.ui.nextMonthButton[0].click()
        this.state.displayedDayEls[remainder].click()
        return
      }

      // incoming day is in the displayed month
      this.state.displayedDayEls[index].click()
    }

    switch (e.keyCode) {
      // down moves one week forward, changing month if necessary
      case KEYS.down:
        e.preventDefault()
        shiftSelection(7)
        break;

      // enter submits
      case KEYS.enter:
        this.ui.submitButton[0].click()
        break;

      // left moves one day back, changing month if necessary
      case KEYS.left:
        e.preventDefault()
        shiftSelection(-1)
        break;

      // left moves one day forward, changing month if necessary
      case KEYS.right:
        e.preventDefault()
        shiftSelection(1)
        break;

      // up moves one week back, changing month if necessary
      case KEYS.up:
        e.preventDefault()
        shiftSelection(-7)
        break;

      default:
        break;
    }
  },

  handleHideButtonClick(e) {
    this.hideModal(e)
  },

  handleNextMonthButtonClick() {
    const nextMonthFirstDay = new Date(this.state.displayedYear, this.state.displayedMonth, 32)

    this.state.displayedYear = nextMonthFirstDay.getFullYear()
    this.state.displayedMonth = nextMonthFirstDay.getMonth()

    this.render()
  },

  handlePrevMonthButtonClick() {
    const prevMonthLastDay = new Date(this.state.displayedYear, this.state.displayedMonth, 0)

    this.state.displayedYear = prevMonthLastDay.getFullYear()
    this.state.displayedMonth = prevMonthLastDay.getMonth()

    this.render()
  },

  handleShowButtonClick() {
    this.setCalendarDateToPageDate()
    this.render()
    this.show(this.ui.modal[0], false, this.state.selectedDisplayedDayEl) // in the extended Modal
  },

  handleSubmitButtonClick(e) {
    const pageDaySelected = this.state.selectedYear === this.pageYear
      && this.state.selectedMonth === this.pageMonth
      && this.state.selectedDate === this.pageDate

    if (pageDaySelected) {
      e.preventDefault()
      this.hideModal(e)
      return
    }
  },

  handleWindowKeydown(e) {
    switch (e.keyCode) {
      case KEYS.esc:
        this.hideModal(e)
        break;

      default:
        break;
    }
  },

  Head() {
    let dayCells = ""

    DAY_NAMES.forEach((dayName) => {
      dayCells += `<div class="${CELL_CLASSES} pad-1-vertical type-bold">${dayName}</div>`
    })

    const html = `
      <div class="${WRAPPER_CLASSES} calendar-modal__head">
        ${dayCells}
      </div>
    `

    return html
  },

  hideModal(e) {
    this.hide(e, this.ui.modal[0]) // in the extended Modal
    this.toggleDayEventListeners(false)
  },

  render() {
    const displayedMonthLastDate = new Date(this.state.displayedYear, this.state.displayedMonth + 1, 0)
    const displayingTodayMonth = this.state.displayedYear === this.todayYear && this.state.displayedMonth === this.todayMonth
    const displayingSelectedMonth = this.state.displayedYear === this.state.selectedYear && this.state.displayedMonth === this.state.selectedMonth

    this.state.displayedMonthLastDate = displayedMonthLastDate.getDate()

    if (this.state.selectedDisplayedDayEl) { // if the previously rendered month included the selected day
      this.ui.submitButton[0].setAttribute('disabled', '')
    }

    if (this.state.displayedDayEls) {
      // clean up listeners on outgoing month's days
      this.toggleDayEventListeners(false)
    }

    const monthName = MONTH_NAMES[this.state.displayedMonth]
    const displayedMonthAndYear = `${monthName} ${this.state.displayedYear}`

    this.ui.month[0].innerText = displayedMonthAndYear
    this.ui.head[0].innerHTML = this.Head()
    this.ui.days[0].innerHTML = this.Days({
      displayingTodayMonth: displayingTodayMonth,
      displayingSelectedMonth: displayingSelectedMonth,
      displayedMonthLastDate: displayedMonthLastDate,
      displayedMonthAndYear: displayedMonthAndYear,
    })

    if (displayingTodayMonth) {
      this.ui.prevMonthButton[0].classList.add("invisible")
    } else {
      this.ui.prevMonthButton[0].classList.remove("invisible")
    }

    this.state.selectedDisplayedDayEl = this.ui.days[0].querySelector(`.${SELECTED_CLASS}`) || null
    this.state.displayedDayEls = Array.prototype.slice.call(this.ui.days[0].querySelectorAll(`[${DAY_DATE_ATTR}]`))

    if (this.state.selectedDisplayedDayEl) {
      this.state.selectedDisplayedDayEl.focus()
      this.ui.submitButton[0].removeAttribute('disabled')
      this.ui.submitButton[0].href = this.getSubmitButtonHref(this.state.selectedDate)
    } else {
      this.ui.submitButton[0].setAttribute('disabled', '')
    }

    this.toggleDayEventListeners(true)
  },

  setCalendarDateToPageDate() {
    this.state.selectedDate = this.pageDate
    this.state.selectedMonth = this.state.displayedMonth = this.pageMonth
    this.state.selectedYear = this.state.displayedYear = this.pageYear
  },

  toggleDayEventListeners(add = true) {
    let method = 'addEventListener'

    if (!add) {
      method = 'removeEventListener'
    }

    this.state.displayedDayEls.forEach((dayEl) => {
      dayEl[method]('click', this.handleDayClick.bind(this), false)
      dayEl[method]('keydown', this.handleDayKeydown.bind(this), false)
    })
  },
}, Modal)

module.exports = CalendarModal
