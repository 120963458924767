import * as templates from '../templates/membership-upsell-templates'

const DROPDOWN_SELECTOR = '[data-membership-upsell-dropdown]'
const HAS_CHANGED_CLASS = 'membership-upsell--has-changed'
const TEMPLATE_ATTR = 'data-membership-upsell-template'
const UPDATING_CLASS = 'membership-upsell-updating'

export default {
  ui: {
    upsellTemplate: `[${TEMPLATE_ATTR}]`,
    outlet: '[data-membership-upsell-outlet]',
    productFields: '[data-product-fields]',
    dropdowns: DROPDOWN_SELECTOR,
    message: '[data-membership-upsell-message]',
    mobileMessage: '[data-membership-upsell-mobile-message]',
    buttons: '[data-membership-upsell-button]',
    details: '[data-membership-upsell-details]',
    selectionPriceOutlets: '[data-membership-upsell-selection-price-outlet]'
  },

  events: {
    'change {dropdowns}': 'handleProductFieldChange',
  },

  initialize(options) {
    this.disableSubmission(true)
    this.setVariables()
    this.renderMembership(this.defaultMembership, this.defaultMessage)
  },

  disableSubmission(disable) {
    this.ui.buttons.forEach(button => {
      button.disabled = disable
    })
  },

  setVariables() {
    const isDefaultMembership = (membership) => membership.id === window.default_membership_id

    this.memberships = window.memberships
    this.defaultMembership = this.memberships[0]

    if (window.default_membership_id) {
      this.memberships.filter(isDefaultMembership)[0] || this.defaultMembership // IE 10 support necessitates using filter()[0] instead of find()
    }

    this.defaultMessage = this.ui.message[0].textContent
    this.upsellTemplate = null

    if (this.ui.upsellTemplate.length) {
      const templateName = this.ui.upsellTemplate[0].getAttribute(TEMPLATE_ATTR)

      this.upsellTemplate = new templates[templateName](
        this.ui.upsellTemplate[0].textContent
      )
    }
  },

  renderMembership(upsell, message, mobileMessage) {
    if (this.upsellTemplate) {
      this.markChanged()

      this.ui.outlet[0].classList.add(UPDATING_CLASS)
      this.ui.message[0].textContent = message
      this.ui.mobileMessage[0].textContent = mobileMessage || message
      this.ui.outlet[0].innerHTML = this.upsellTemplate.render(upsell)
      this.ui.outlet[0].classList.remove(UPDATING_CLASS)
    }
  },

  handleProductFieldChange() {
    const { selectionAdultCount, selectionChildCount, selectionPrice } = this.selectionTotals()
    const hasSelection = selectionAdultCount + selectionChildCount > 0

    let message = this.defaultMessage
    let mobileMessage = this.defaultMessage
    let upsell = this.defaultMembership

    if (hasSelection) {
      const membership = this.bestFitMembership(selectionAdultCount, selectionChildCount)

      if (membership.id) {
        const priceDifference = Math.round(membership.price - selectionPrice)
        const relativeCurrencyDifference = `$${Math.abs(priceDifference)} ${priceDifference >= 0 ? 'more' : 'less'}`

        message = `Get in free year-round for ${relativeCurrencyDifference}.`
        mobileMessage = `For only ${relativeCurrencyDifference} enjoy unlimited FREE admission.`
        upsell = {...membership, priceDifference}
      } else {
        const highestPrice = (prev, current) => prev.price > current.price ? prev : current
        upsell = this.memberships.reduce(highestPrice, {})
      }
    }

    this.disableSubmission(!hasSelection)
    this.renderSelectionPrice(selectionPrice)
    this.renderMembership(upsell, message, mobileMessage)
  },

  renderSelectionPrice(price) {
    this.ui.selectionPriceOutlets.forEach(selectionPriceOutlet => {
      let content = ""

      if (price) {
        content = `Total<br>$${price.toFixed(2)}`
      }

      selectionPriceOutlet.innerHTML = content
    })
  },

  selectionTotals() {
    const totals = (acc, cur) => {
      const price = Number(cur.dataset.productPrice)

      const isChild = cur.dataset.productChildTicket === "true"
      const quantity = Number(cur.querySelector(DROPDOWN_SELECTOR).value)

      if (isChild) {
        acc.selectionChildCount += quantity
      } else {
        acc.selectionAdultCount += quantity
      }

      acc.selectionPrice += price * quantity

      return acc
    }

    return this.ui.productFields.reduce(totals, { selectionAdultCount: 0, selectionChildCount: 0 , selectionPrice: 0 })
  },

  bestFitMembership(adultCount, childCount) {
    const applicable = function(membership) {
      const isCoveredByAllowedAdults = membership.numberOfAdults >= adultCount + childCount
      const isCoveredByAllowedAdultsAndChildren = membership.numberOfAdults >= adultCount && membership.numberOfChildren >= childCount
      return isCoveredByAllowedAdults || isCoveredByAllowedAdultsAndChildren
    }

    const lowestPrice = (prev, current) => prev.price < current.price ? prev : current

    const applicableMemberships = this.memberships.filter(applicable)
    return applicableMemberships.reduce(lowestPrice, {})
  },

  markChanged() {
    if (!this.ui.outlet[0].classList.contains(HAS_CHANGED_CLASS)) {
      this.ui.outlet[0].classList.add(HAS_CHANGED_CLASS)
    }
  },
}
