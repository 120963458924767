const SUGGESTION_ATTR = 'data-donation-suggestion'

export default {
  ui: {
    change: '[data-donation-change]',
    form: '[data-donation-form]',
    input: '[data-donation-input]',
    submitButton: '[data-donation-submit-button]',
    suggestions: `[${SUGGESTION_ATTR}]`,
  },

  events: {
    'click {change}': 'changeDonation',
    'click {suggestions}': 'submitSuggestion',
    'click {submitButton}': 'submit',
  },

  state: {
    focusOnEdit: null,
  },

  changeDonation() {
    this.el.classList.add('-editing')
    this.ui.input[0].focus()
    this.ui.input[0].scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },

  submit() {
    this.ui.submitButton[0].classList.add('btn-loading')
    this.ui.submitButton[0].disabled = true
    this.ui.form[0].submit()
  },

  submitSuggestion(e) {
    this.ui.input[0].value = Number(e.target.getAttribute(SUGGESTION_ATTR))
    this.submit()
  },

  donationAmount() {
    return parseFloat(this.form.dataset.donationAmount)
  },
}
