import Mustache from 'mustache'

class Tickets {
  constructor(template) {
    this.template = template

    Mustache.parse(this.template)
  }

  membershipBenefits = (membership) => {
    const childText = membership.childText ? `, ${membership.childText}` : ''
    const guestText = membership.guestText ? `${membership.guestText}` : ''
    return `${membership.adultText}${guestText}${childText}, as well as discounts and other member benefits.`
  }

  render({ priceDifference, name, ...membership}) {
    const price = parseFloat(membership.price)
    const context =  {
      ...membership,
      title: name + ' Membership',
      benefits: this.membershipBenefits(membership),
      price: `$${price.toFixed(2)}`,
    }

    if (priceDifference) {
      if (priceDifference > 0) {
        context.priceDifference =
          `For only $${priceDifference} more enjoy unlimited FREE admission`
      } else {
        context.priceDifference =
          `For $${Math.abs(priceDifference)} less enjoy unlimited FREE admission`
      }
    }

    return Mustache.render(this.template, context);
  }
}

class TimedTickets {
  constructor(template) {
    this.template = template

    Mustache.parse(this.template)
  }

  membershipBenefits = (membership) => {
    const childText = membership.childText ? `, ${membership.childText}` : ''
    const guestText = membership.guestText ? `${membership.guestText}` : ''
    return `${membership.adultText}${guestText}${childText}.`
  }

  render({ priceDifference, name, ...membership}) {
    const price = parseFloat(membership.price)
    const context =  {
      ...membership,
      title: name + ' Membership',
      benefits: this.membershipBenefits(membership),
      price: `$${price.toFixed(0)}`,
    }

    if (priceDifference) {
      if (priceDifference > 0) {
        context.priceDifference =
          `For only $${priceDifference} more, you could enjoy:`
      } else {
        context.priceDifference =
          `For $${Math.abs(priceDifference)} less, you could enjoy:`
      }
    }

    return Mustache.render(this.template, context);
  }
}

export { Tickets, TimedTickets }
