import Washi from 'washi'
import { BigNum } from '../../utils/currency'

import { stringIsPresent } from '../../utils/is-present'
import { hide, show } from '../../utils/visibility'

const $ = Washi.$

export default {

  ui: {
    title          : '[data-title]',
    confirm        : '[data-confirm]',
    confirmButton  : '[data-confirm] button',
    dismiss        : '[data-dismiss]',
    dismissButton  : '[data-dismiss] button',
    donation       : '[data-donation]',
    donationAmount : '[data-donation-amount]'
  },

  events: {
    'click {confirmButton}' : 'addDonation',
    'click {dismissButton}' : 'dismiss'
  },

  states: {
    added: {
      title: {
        text    : 'Thank you for your donation.',
        visible : true,
        delay   : 150
      },
      confirm: {
        visible : false,
        delay   : 150
      },
      dismiss: {
        visible : false,
        delay   : 150
      },
    },
    removed: {
      title: {
        text : 'Your donation has been removed.'
      },
      confirm: {
        visible : true
      },
      confirmButton: {
        text : 'Re-add your ${ amount } donation',
      },
      dismiss: {
        visible : false
      }
    }
  },

  initialize(options) {
    this.state = 'initial'

    if (this.hasDonation()) {
      this.addDonation()
    }
  },

  donationInput() {
    return this.ui.donation[0]
  },

  donationAmount() {
    let amount = this.ui.donationAmount[0].getAttribute('data-donation-amount')

    if (stringIsPresent(amount)) {
      return BigNum(amount)
    } else {
      return BigNum('0')
    }
  },

  addDonation() {
    this.setState('added')

    this.donationInput().disabled = false

    this.order.update({
      donation : this.donationAmount()
    })
  },

  removeDonation() {
    this.setState('removed')

    this.donationInput().disabled = true

    this.order.update({ donation : BigNum('0') })
  },

  dismiss() {
    this.setState('dismissed')

    hide(this.el)
  },

  hasDonation() {
    return this.donationInput().disabled == false
  },

  setState(newState) {
    let settings = this.states[newState]

    this.state = newState

    if ($.isObject(settings)) {
      for (var key in settings) {
        this.updateUI(this.ui[key], settings[key])
      }
    }
  },

  updateUI(el, { text, delay, visible }) {
    // Set the text, if applicable
    if ($.isString(text) && text != '') {
      let message = $.template(text, { amount: this.donationAmount().round() })

      el.forEach(el => el.innerHTML = message)
    }

    // Set visibility, if applicable
    if (typeof visible === 'boolean') {
      el.forEach(function (item) {
        if (visible === false) {
          item.classList.add('donation-will-hide')
        } else {
          item.classList.remove('donation-will-hide')
        }

        setTimeout(function () {
          visible ? show(item) : hide(item)
        }, delay)
      })
    }
  }

}
